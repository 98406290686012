import './index.scss'

const Privacy = (): JSX.Element => {
  return (
    <>
      <div className="textContainer">
        <h1 className='title'>Privacy Policy</h1>
        <p className='date'>Effective:  25 November 2022</p>
        <h1 id='introduction'>1. Introduction</h1>

        <p>NXG Labs Pte Ltd(referred to as &quot;<strong>we</strong>&quot; and &quot;<strong>our</strong>&quot; and &quot;<strong>us</strong>&quot;) understands and respects your desire to maintain your privacy. It is our priority to maintain your privacy in all of your dealings with us, including through your use of the NXG Wallet (“<strong>App</strong>”). </p>
        <p>NXG Labs Pte Ltd is committed to protecting the privacy of its users, and the following policy (&quot;Policy&quot;) explains how it does so. The websites, software, and goods and services (collectively, &quot;Services&quot;) to which this Policy is posted, linked, or referred are subject to its terms and conditions.</p>
        <p>Your use of the Services constitutes your agreement to and acceptance of this Policy and our Terms of Use, as well as your permission to our collection, use, disclosure, and retention of your information as outlined in this Policy. To further assist you, please read the terms of service. There are limitations in the terms of service that limit our obligation to you and require you to address any issue with us on an individual basis, rather than as part of a class or representative action. DO NOT USE ANY OF THE SERVICES IF YOU DO NOT AGREE WITH ANY PART OF THIS PRIVACY POLICY OR OUR TERMS OF USE.</p>
        <p>Please be aware that this Policy does not cover information we receive from third parties whose websites or services you access via the Services or that you send to us through email, text message, or other electronic communication or offline.</p>
        <h1 id='the-blockchain'>2. The Blockchain</h1>

        <p>Individual transactions broadcast through NXG Wallet may be seen by the public due to the public nature of several blockchains. Your public sending address, the public receiving address, the amount sent or received, and any additional information that a user chooses to publish in a transaction are all examples of this. It may be very difficult, if not impossible, to alter or erase information from a blockchain after it has been published. Any interested party, including law enforcement, might theoretically piece together a user&#39;s identity from their transactions and addresses at any time in the future. Users should educate themselves on the inner workings of blockchain privacy and transparency.</p>
        <h1 id='what-we-collect'>3. What We Collect</h1>

        <p>We get information about you in a range of ways. Information You Give Us. Information we collect from you may include:</p>
        <ul>
          <li><p>Identity information, such as your first name, last name, username or similar identifier, title, date of birth, and gender;</p></li>

          <li><p>Contact information, such as your postal address, email address and telephone number;</p></li>


          <li><p>Profile information, such as your username and password, interests, preferences, feedback, and survey responses;</p></li>


          <li><p>Feedback and correspondence, such as information you provide in your responses to surveys when you participate in market research activities, report a problem with Service, receive customer support, or otherwise correspond with us;</p></li>


          <li><p>Financial information, such as your credit card or other payment card details, bank account number and bank routing number; financial assets holdings;</p></li>


          <li><p>Transaction information, such as details about purchases you make through the Service and billing details; and</p></li>


          <li><p>Data that is strictly technical in nature, such as your Ethereum wallet address and any other wallet address from any other wallet service providers, the API-key, and transactional data from the underlying network. NXG Wallet and its affiliated plugins and mobile apps (&quot;Crushe&quot;) will never ask for your wallet address.</p></li>

          <li><p>Usage information, such as information about how you use the Service and interact with us;</p></li>

          <li><p>Marketing information, such as your preferences for receiving marketing communications and details about how you engage with them;</p></li>

        </ul>
        <h1 id='how-we-use-information-you-provide-us'>4. How We Use Information You Provide Us</h1>

        <h3 id='to-provide-our-service'>4.1 To provide our service</h3>

        <p>We will use your personal information in the following ways:</p>
        <ul>
          <li><p>To enable you to access and use the Services</p></li>

          <li><p>To provide and deliver products and services that you may request.</p></li>

          <li><p>To process and complete transactions, and send you related information, including purchase confirmations and invoices</p></li>

          <li><p>To send information, including confirmations, technical notices, updates, security alerts, and support and administrative messages.</p></li>

        </ul>
        <h3 id='to-comply-with-law'>4.2 To comply with law</h3>

        <p>We use your personal information as we believe necessary or appropriate to comply with applicable laws (including anti-money laundering (AML) laws and know-your-customer (KYC) requirements), lawful requests and legal process, such as to respond to subpoenas or requests from government authorities.  </p>
        <h3 id='to-communicate-with-you'>4.3 To communicate with you</h3>

        <p>We use your personal information to communicate about promotions, upcoming events, and other news about products and services offered by us and our selected partners.</p>
        <h3 id='to-optimize-our-platform'>4.4 To optimize our platform</h3>

        <p>In order to optimize your user experience, we may use your personal information to operate, maintain, and improve our Services. We may also use your information to respond to your comments and questions regarding the Services, and to provide you and other users with general customer service. </p>
        <h3 id='with-your-consent'>4.5 With your consent</h3>

        <p>We may use or share your personal information with your consent, such as when you consent to let us post your testimonials or endorsements on our Sites, you instruct us to take a specific action with respect to your personal information, or you opt into third party marketing communications.</p>
        <h3 id='for-compliance-fraud-prevention-and-safety'>4.6 For compliance, fraud prevention, and safety</h3>

        <p>Your data may be used to prevent, detect, and take action about any fraudulent, unauthorized, or unlawful conduct.</p>
        <h1 id='sharing-personal-information'>5. Sharing Personal Information</h1>

        <p>We will not disclose your Personal Information other than as described below, and we do not and will not sell your Personal Information to anyone.</p>
        <p>We may share the Personal Information we collect from and about you to fulfill the purpose for which you provided it; with your consent; for legal, protection, and safety purposes;  </p>
        <ul>
          <li>
            <p>
              <strong>Affiliates.</strong>  We may disclose your personal information to our subsidiaries and corporate affiliates (i.e. our family of companies that are related by common ownership or control) for purposes consistent with this Privacy Policy.
            </p>
          </li>

        </ul>
        <ul>
          <li>
            <p>
              <strong>Business Transfers.</strong>  We may share personal information when we do a business deal, or negotiate a business deal, involving the sale or transfer of all or a part of our business or assets. These deals can include any merger, financing, acquisition, or bankruptcy transaction or proceeding.
            </p>
          </li>

        </ul>
        <ul>
          <li>
            <p>
              <strong>Professional Advisors and Service Providers.</strong> We may share information with those who need it to do work for us. These recipients may include third party companies and individuals to administer and provide the Service on our behalf (such as bill and credit card payment processing, customer support, hosting, email delivery and database management services), as well as lawyers, bankers, auditors, and insurers.
            </p>
          </li>

        </ul>
        <h1 id='compliance-with-laws-and-law-enforcement-protection-and-safety'>6. Compliance with Laws and Law Enforcement; Protection and Safety. </h1>

        <p>We may share personal information for legal, protection, and safety purposes.</p>
        <ul>
          <li><p>pWe may share information to comply with laws, including KYC and AML requirements.</p></li>

          <li><p>We may share information to respond to lawful requests and legal processes.</p></li>


          <li><p>We may share information to protect the rights and property of the Company, our agents, customers, and others. This includes enforcing our agreements, policies, and terms of use.</p></li>


          <li><p>We may share information in an emergency. This includes protecting the safety of our employees and agents, our customers, or any person.</p></li>


          <li><p>Other. </p></li>


          <li><p>You may permit us to share your personal information with other companies or entities of your choosing. Those uses will be subject to the privacy policies of the recipient entity or entities.</p> </li>

        </ul>
        <h1 id='service-providers'>7. Service Providers</h1>

        <p>We may contract with third parties to perform functions related to the Services (&quot;Service Provider&quot;). In general, Service Providers will have access to your Personal Information only to the extent needed to perform their business functions but may not use or share that personal information for purposes outside the scope of their functions related to the Services.</p>

        <h1 id='third-party-links'>8. Third-party links</h1>

        <p>There are external websites and apps that may be accessed using the Services&#39; connections. When you leave the Site (NXG Wallet) by clicking on such a link or are referred to a third-party website or application, you are no longer subject to the terms of this Policy. We do not control and are not responsible for, the privacy and security practices and policies of any third-party sites or apps to which we may offer links. Any information you provide on those sites is subject to that third party&#39;s privacy policy and we are not responsible for the privacy security practices and policies of those third-party sites or applications.</p>
        <h1 id='policy-changes'>9. Policy Changes</h1>

        <p>We reserve the right to make changes to this Policy at any time. Please check back often to be apprised of any changes we may have made to our privacy policies and procedures. In the event of updates, we will revise the date shown above. Changes to this Privacy Policy will become effective when we publish the updated policy on the Sites and/or when we begin using the new policy (or as otherwise indicated at the time of posting).To the extent that you use the Sites or Services after the publication of any new Privacy Policy, you will be deemed to have accepted the terms of the modified Privacy Policy. You are free to cease using the Services at any time if you do not agree with the modifications. </p>
        <h1 id='contact'>10. Contact</h1>

        <p>Any feedback or inquiries about this Policy may be sent to: <a href='mailto:support@nxgwallet.com' target='_blank' rel="noreferrer">support@nxgwallet.com</a></p>
      </div>
    </>
  );
}

export default Privacy;